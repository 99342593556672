<template>
  <div>
    <!-- widget report data -->

    <!-- Survey types -->
    <SurveySentCount v-if="widgetType === 'SURVEY_SENT_COUNT'" :widgetId="widgetId" />
    <SurveyTakeRate v-if="widgetType === 'SURVEY_TAKE_RATE'" :widgetId="widgetId" />
    <SurveyResponseRate v-if="widgetType === 'SURVEY_RESPONSE_RATE'" :widgetId="widgetId" />
    <SurveyAverageRating v-if="widgetType === 'SURVEY_AVERAGE_RATING'" :widgetId="widgetId" />
    <SurveyCompletedCount v-if="widgetType === 'SURVEY_COMPLETED_COUNT'" :widgetId="widgetId" />
    <SurveyAvgResponseTime v-if="widgetType === 'SURVEY_AVG_RESPONSE_TIME'" :widgetId="widgetId" />
    <SurveyScores v-if="widgetType === 'SURVEY_SCORES'" :widgetId="widgetId" />
    <SatisfactionBySource
      v-if="widgetType === 'SURVEY_SATISFACTION_BY_SOURCE'"
      :widgetId="widgetId"
    />
    <VolumeBySource v-if="widgetType === 'SURVEY_VOLUME_BY_SOURCE'" :widgetId="widgetId" />
    <SatisfactionDayparts v-if="widgetType === 'SURVEY_DAYPARTS'" :widgetId="widgetId" />

    <!-- Customer types -->
    <CustomerSavedCount v-if="widgetType === 'CUSTOMER_SAVED'" :widgetId="widgetId" />
    <CustomerCreatedCount v-if="widgetType === 'CUSTOMER_CREATED_COUNT'" :widgetId="widgetId" />

    <!-- Review types -->
    <ReviewCreatedCount v-if="widgetType === 'REVIEW_CREATED_COUNT'" :widgetId="widgetId" />
    <ReviewAverageOnlineRating
      v-if="widgetType === 'REVIEW_AVERAGE_ONLINE_RATING'"
      :widgetId="widgetId"
    />
    <ReviewCurrentReviewsAndRatings
      v-if="widgetType === 'REVIEW_CURRENT_AND_RATINGS'"
      :widgetId="widgetId"
    />
    <!-- <ReviewsAndRatings v-if="widgetType === 'REVIEW_AND_RATINGS'" :widgetId="widgetId" /> -->
    <ReviewsAndRatingsV2 v-if="widgetType === 'REVIEW_AND_RATINGS'" :widgetId="widgetId" />
    <ReviewsPerMonth v-if="widgetType === 'REVIEWS_PER_MONTH'" :widgetId="widgetId" />
    <ReviewClicks v-if="widgetType === 'REVIEW_CLICKS'" :widgetId="widgetId" />
  </div>
</template>

<script>
// Survey
import SurveySentCount from './WidgetTypes/Survey/SurveySentCount'
import SurveyTakeRate from './WidgetTypes/Survey/SurveyTakeRate'
import SurveyResponseRate from './WidgetTypes/Survey/SurveyResponseRate'
import SurveyAverageRating from './WidgetTypes/Survey/SurveyAverageRating'
import SurveyCompletedCount from './WidgetTypes/Survey/SurveyCompletedCount'
import SurveyAvgResponseTime from './WidgetTypes/Survey/SurveyAvgResponseTime'
import SurveyScores from './WidgetTypes/Survey/SurveyScores'
import SatisfactionBySource from './WidgetTypes/Survey/SatisfactionBySource'
import VolumeBySource from './WidgetTypes/Survey/VolumeBySource'
import SatisfactionDayparts from './WidgetTypes/Survey/SatisfactionDayparts'

// Customer
import CustomerSavedCount from './WidgetTypes/Customer/CustomerSavedCount.vue'
import CustomerCreatedCount from './WidgetTypes/Customer/CustomerCreatedCount'

// Review
import ReviewCreatedCount from './WidgetTypes/Review/ReviewCreatedCount'
import ReviewAverageOnlineRating from './WidgetTypes/Review/ReviewAverageOnlineRating.vue'
import ReviewCurrentReviewsAndRatings from './WidgetTypes/Review/ReviewCurrentReviewsAndRatings.vue'
// import ReviewsAndRatings from './WidgetTypes/Review/ReviewsAndRatings'
import ReviewsAndRatingsV2 from './WidgetTypes/Review/ReviewsAndRatingsV2'
import ReviewsPerMonth from './WidgetTypes/Review/ReviewsPerMonth'
import ReviewClicks from './WidgetTypes/Review/ReviewClicks'

export default {
  name: 'WidgetBody',
  props: ['widgetId', 'widgetType'],
  components: {
    // Survey
    SurveySentCount,
    SurveyTakeRate,
    SurveyResponseRate,
    SurveyAverageRating,
    SurveyCompletedCount,
    SurveyAvgResponseTime,
    SurveyScores,
    SatisfactionBySource,
    VolumeBySource,
    SatisfactionDayparts,

    // Customer
    CustomerSavedCount,
    CustomerCreatedCount,

    // Review
    ReviewCreatedCount,
    ReviewAverageOnlineRating,
    ReviewCurrentReviewsAndRatings,
    ReviewsAndRatingsV2,
    // ReviewsAndRatings,
    ReviewsPerMonth,
    ReviewClicks,
  },
}
</script>
