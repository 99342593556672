<template>
  <div id="area-graph-wrapper">
    <div class="mx--4 mb--4">
      <div v-if="series">
        <apexchart
          type="area"
          height="115"
          width="100%"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div>
      <div style="height: 30px" v-else></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AreaGraph',
  props: {
    // dataset needs to be an object where keys are dates, and values are [valueType]
    dataSet: {
      type: Object,
      required: true,
    },
    valueType: {
      type: String,
      required: true,
      validator(value) {
        return ['integer', 'float', 'percentage', 'time'].includes(value)
      },
    },
    granularity: {
      type: String,
      required: true,
      validator(value) {
        return ['day', 'week', 'month', 'year'].includes(value)
      },
    },
  },
  computed: {
    series() {
      return Object.keys(this.dataSet).length
        ? [
            {
              data: Object.entries(this.dataSet)
                .map((e) => {
                  const value = e[1] || 0
                  return {
                    x: this.$moment(e[0], 'YYYY-MM-DD').format('MMM DD, YYYY'),
                    y:
                      this.valueType !== 'time'
                        ? this.valueType === 'float'
                          ? Number.parseFloat(value).toFixed(1)
                          : value
                        : value / 60,
                  }
                })
                .sort((a, b) =>
                  this.$moment(a.x, 'MMM DD, YYYY').isBefore(this.$moment(b.x, 'MMM DD, YYYY'))
                    ? -1
                    : 1
                ),
            },
          ]
        : null
    },

    allZero() {
      return Object.values(this.dataSet).every((val) => val === 0)
    },

    allSame() {
      const dataSetArray = Object.values(this.dataSet)
      return dataSetArray?.length ? dataSetArray.every((val) => val === dataSetArray[0]) : false
    },

    chartOptions() {
      return {
        chart: {
          type: 'area',
          zoom: { enabled: false },
          toolbar: { show: false },
          height: '100%',
        },
        stroke: {
          show: true,
          lineCap: 'butt',
          curve: 'smooth',
          colors: ['#2c7be5'],
          width: 2,
        },
        fill: {
          opacity: 0.3,
        },
        xaxis: {
          axisBorder: { show: false },
          axisTicks: { show: false },
          labels: { show: false, maxHeight: 15 },
          tooltip: { enabled: false },
        },
        yaxis: {
          show: true,
          tickAmount: 1,
          ...(this.allSame ? { min: (min) => 0 } : { min: (min) => min }),
          ...(this.allZero ? { max: (max) => 1 } : { max: (max) => max }),
          labels: {
            formatter: (val, _) => {
              return `${this.processValue(val)}`
            },
          },
        },
        dataLabels: { enabled: false },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
          },
          y: {
            formatter: (val, _) => {
              return this.processValue(val)
            },
            title: {
              formatter: this.tooltipTitleFormatter,
            },
          },
          marker: {
            show: false,
          },
        },
        legend: { show: false },
        noData: { text: 'No data to show for selected time range' },
      }
    },
  },
  methods: {
    tooltipTitleFormatter(_, d) {
      return this.formatDateLabel(d.w.config.series[0].data[d.dataPointIndex].x) + ':'
    },
    processValue(value) {
      return this.valueType === 'integer'
        ? value
        : this.valueType === 'float'
        ? `${Number.parseFloat(value).toFixed(1)}`
        : this.valueType === 'percentage'
        ? value + '%'
        : this.formatTime(value)
    },
    formatTime(minutes) {
      let hours
      let days
      let remainingMinutes
      const oneDayMinutes = 1440 // 24 * 60

      if (minutes < 0) {
        minutes *= -1
        return `-${timeConversion(minutes)}`
      } else if (minutes < 60) {
        return `${Math.round(minutes)}m`
      } else if (minutes > 60 && minutes < oneDayMinutes) {
        hours = Math.floor(minutes / 60)
        remainingMinutes = Math.round(minutes % 60)
        return `${hours}h ${remainingMinutes}m`
      } else {
        days = Math.floor(minutes / 60 / 24)
        remainingMinutes = minutes % oneDayMinutes
        hours = Math.floor(remainingMinutes / 60)
        return `${days}d ${hours}h`
      }
    },
    formatDateLabel(label) {
      if (this.granularity === 'week') {
        const date = this.$moment(label, 'MMM DD, YYYY')
        return `${date.format('MMM DD')} - ${date.add(6, 'days').format('MMM DD')}`
      } else if (this.granularity === 'month') {
        return `${this.$moment(label, 'MMM DD, YYYY').format('MMM YYYY')}`
      } else if (this.granularity === 'year') {
        return `${this.$moment(label, 'MMM DD, YYYY').format('YYYY')}`
      } else {
        return label
      }
    },
  },
}
</script>
