<template>
  <div>
    <div v-if="!loading">
      <div class="row mt-3">
        <div class="col">
          <div class="d-flex">
            <div class="mr-3 card mb-0 p-2 bg-light">
              <div>Reviews: {{ totalReviews }}</div>
            </div>
            <div class="card mb-0 p-2 bg-light">
              <div>Average Rating: {{ averageRating.toFixed(1) }}</div>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <SingleSelectFilter
            :options="granularityOptions"
            :initial="selectedGranularity"
            @update="granularityChange"
            width="80"
          />
        </div>
      </div>
      <div id="rnrchart" style="width: 100%; height: 260px"></div>
    </div>

    <div v-else><ScoreComparisonLoader /></div>
  </div>
</template>

<script>
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive'
import moment from 'moment-timezone'
import ScoreComparisonLoader from '../../SkeletonLoaders/ScoreComparisonLoader'
import SingleSelectFilter from '@/components/Modules/Filters/SingleSelectFilter_Object'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'ReviewsAndRatingsV2',
  props: ['widgetId'],
  components: {
    ScoreComparisonLoader,
    SingleSelectFilter,
  },
  data: () => ({
    loading: true,
    granularityOptions: [
      { name: 'Daily', _id: 'day' },
      { name: 'Weekly', _id: 'week' },
      { name: 'Monthly', _id: 'month' },
      { name: 'Yearly', _id: 'year' },
    ],
    selectedGranularity: '',
    totalReviews: 0,
    averageRating: 0,
    seriesData: [],
    ratingOffset: 0,
  }),
  async mounted() {
    await this.fetchReviewsAndRatings({ widgetId: this.widgetId })
    this.selectedGranularity = this.selectGranularity
    am5.addLicense(this.selectConfigByName('AMKEY'))
    this.loading = false
    this.seriesData = await this.processData()
    this.createChart()
  },
  beforeDestroy() {
    if (this.root) {
      this.root.dispose()
    }
  },
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject', 'selectGranularity']),
    ...mapGetters(['selectConfigByName']),
  },
  methods: {
    ...DashboardModule.mapActions(['fetchReviewsAndRatings']),
    async processData() {
      let total = 0
      let sumRatings = 0
      let maxCount = 0
      let maxRating = 0
      let minRating = 5
      const processedData = []

      Object.keys(this.selectReportValueObject(this.widgetId)).forEach((date) => {
        const data = this.selectReportValueObject(this.widgetId)[date]
        total += data.count
        sumRatings += data.avgRating * data.count
        if (data.count > maxCount) maxCount = data.count
        if (data.avgRating < minRating) minRating = data.avgRating
        if (data.avgRating > maxRating) maxRating = data.avgRating
        processedData.push({
          date: moment(date).valueOf(),
          count: data.count,
          avgRating: data.avgRating,
          ratingPositionOffset: 0,
        })
      })

      const baseYOffset = maxCount + maxCount * 0.7
      const halfBaseYOffset = baseYOffset / 2
      const ratingSpread = maxRating - minRating || 5
      processedData.forEach((el) => {
        el.ratingPositionOffset =
          baseYOffset + (halfBaseYOffset * (parseFloat(el.avgRating) - minRating)) / ratingSpread
      })

      this.totalReviews = total
      this.averageRating = total ? sumRatings / total : 0

      return processedData
    },
    createChart() {
      const data = this.seriesData
      let root = am5.Root.new('rnrchart')

      const myTheme = am5.Theme.new(root)
      myTheme.rule('AxisLabel', ['minor']).setAll({
        dy: 1,
      })

      root.setThemes([am5themes_Animated.new(root), myTheme, am5themes_Responsive.new(root)])

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          paddingLeft: 15,
          paddingRight: 25,
          ...(Object.keys(this.selectReportValueObject(this.widgetId)).length > 14
            ? {
                pinchZoomX: true,
                wheelY: 'zoomX',
                wheelX: 'panX',
              }
            : {}),
        })
      )

      let xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 40 })
      let xAxis = chart.xAxes.push(
        am5xy.DateAxis.new(root, {
          baseInterval: { timeUnit: this.selectedGranularity, count: 1 },
          renderer: xRenderer,
        })
      )
      xRenderer.labels.template.setAll({
        oversizedBehavior: 'wrap',
        textAlign: 'center',
      })
      xAxis.onPrivate('cellWidth', function (cellWidth) {
        xRenderer.labels.template.set('maxWidth', cellWidth)
      })
      xAxis.get('dateFormats')['month'] = 'MMM yyyy'

      var yRenderer = am5xy.AxisRendererY.new(root, {})
      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: yRenderer,
          min: 0,
          extraMax: 0.1,
        })
      )

      xAxis.get('renderer').grid.template.set('visible', false)
      yAxis.get('renderer').labels.template.set('visible', false)

      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Review Count',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'count',
          valueXField: 'date',
        })
      )
      series1.columns.template.setAll({ strokeOpacity: 0, cornerRadiusTL: 10, cornerRadiusTR: 10 })
      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 1,
          sprite: am5.Label.new(root, {
            text: '{valueY}',
            centerY: am5.p100,
            centerX: am5.p50,
            populateText: true,
            dy: 1, // Adjust the distance above the bar
          }),
        })
      })

      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: 'Average Rating',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'ratingPositionOffset',
          valueXField: 'date',
          tooltip: am5.Tooltip.new(root, { labelText: '{valueY}' }),
        })
      )
      series2.strokes.template.set('strokeWidth', 2)
      series2.bullets.push(function (root, series, dataItem) {
        var container = am5.Container.new(root, {
          centerX: am5.p50,
          centerY: am5.p50,
        })

        container.children.push(am5.Circle.new(root, { radius: 14, fill: series.get('fill') }))

        container.children.push(
          am5.Label.new(root, {
            centerX: am5.p50,
            centerY: am5.p50,
            fill: am5.color('#fff'),
            text: dataItem.dataContext.avgRating || '0',
          })
        )

        return am5.Bullet.new(root, {
          sprite: container,
        })
      })

      series1.data.setAll(data)
      series2.data.setAll(data)

      this.root = root
    },
    async granularityChange({ _id: granularity }) {
      this.loading = true
      this.selectedGranularity = granularity
      this.root.dispose()
      await this.fetchReviewsAndRatings({ widgetId: this.widgetId, subGranularity: granularity })
      this.loading = false
      this.seriesData = await this.processData()
      this.createChart()
    },
  },
}
</script>
