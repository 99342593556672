<template>
  <div v-if="!loading" class="p-4">
    <div v-for="(survey, index) in surveyScores" :key="index" class="my-3">
      <div class="row p-0 align-items-center">
        <!-- emoji -->
        <div class="col-2 d-flex justify-content-center">
          <img
            :src="`/img/ratings/${survey.rating}.png`"
            :alt="`rating ${survey.rating} emoji`"
            class="d-block"
            contain
            height="30px"
          />
        </div>

        <!-- count -->
        <div class="col-3 d-flex p-0 justify-content-center">
          <div style="font-size: 20px" class="font-weight-bold mt-1">
            {{ survey.count }}
          </div>
        </div>

        <!-- percentage bar -->
        <div class="col">
          <div class="progress bg-light" style="min-height: 22px">
            <div
              class="progress-bar"
              role="progressbar"
              style="background-color: var(--primary)"
              :style="`width: ${Math.round((survey.count / totalCount) * 100)}%`"
            >
              <h5 v-if="displayPercent(survey.count)" class="mt-3 text-left ml-3">
                {{ `${Math.round((totalCount ? survey.count / totalCount : 0) * 100)}%` }}
              </h5>
            </div>
            <div
              v-if="!displayPercent(survey.count)"
              style="margin-top: 0.4em"
              class="text-left ml-3"
            >
              <h5>
                {{ `${Math.round((totalCount ? survey.count / totalCount : 0) * 100)}%` }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else>
    <ScoreComparisonLoader />
  </div>
</template>

<script>
import Comparison from '../../ReportTypes/Comparison'
import ScoreComparisonLoader from '../../SkeletonLoaders/ScoreComparisonLoader'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'SurveyScores',
  props: ['widgetId'],
  data: () => ({
    loading: true,
    totalCount: 0,
    comparisons: {},
  }),
  components: {
    Comparison,
    ScoreComparisonLoader,
  },
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject']),

    surveyScores() {
      let scores = this.selectReportValueObject(this.widgetId)
      let properties = Object.keys(scores)
        .sort((a, b) => b - a)
        .map((item) => parseInt(item))

      let finalScores = []
      properties.forEach((prop) => {
        finalScores.push({
          rating: prop,
          count: scores[prop],
        })
      })

      return finalScores
    },
  },
  methods: {
    ...DashboardModule.mapActions(['fetchSurveyScores']),

    displayPercent(count) {
      if (Math.round((count / this.totalCount) * 100) >= 15) {
        return true
      } else {
        return false
      }
    },
  },
  async mounted() {
    await this.fetchSurveyScores({ widgetId: this.widgetId })
    this.totalCount = Object.values(this.selectReportValueObject(this.widgetId)).reduce(
      (a, b) => a + b
    )
    this.loading = false
  },
}
</script>
