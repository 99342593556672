<template>
  <div>
    <ValueComparison
      :value="selectReportValueAsTime(widgetId)"
      :comparison="selectComparisonTime(widgetId)"
      :reverseCarets="true"
      v-if="!loading"
    />
    <ValueComparisonLoader v-else />
    <AreaGraph
      :dataSet="selectTrendData('averageResponseTime')"
      :valueType="'time'"
      v-if="!!selectTrendData('averageResponseTime')"
      :granularity="selectGranularity"
    />
  </div>
</template>

<script>
import ValueComparison from '@/components/Modules/Dashboard/Widget/ReportTypes/ValueComparison'
import ValueComparisonLoader from '@/components/Modules/Dashboard/Widget/SkeletonLoaders/ValueComparisonLoader'
import AreaGraph from '@/components/Modules/Dashboard/Widget/ReportTypes/AreaGraph'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'SurveyAvgResponseTime',
  props: ['widgetId'],
  components: {
    ValueComparison,
    ValueComparisonLoader,
    AreaGraph,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...DashboardModule.mapGetters([
      'selectComparisonTime',
      'selectReportValueAsTime',
      'selectTrendData',
      'selectGranularity',
    ]),
  },
  methods: {
    ...DashboardModule.mapActions(['fetchSurveyAverageResponseTime']),
  },
  async mounted() {
    await this.fetchSurveyAverageResponseTime({ widgetId: this.widgetId })
    this.loading = false
  },
}
</script>
