<template>
  <div>
    <div class="p-4 justify-content-center" style="height: 300px" v-if="!loading">
      <apexchart
        type="donut"
        height="100%"
        width="100%"
        :series="series"
        :options="chartOptions"
        v-if="sources.length"
      />
      <span v-else>- No Data</span>
    </div>

    <div v-else><ScoreComparisonLoader /></div>
  </div>
</template>

<script>
import ScoreComparisonLoader from '../../SkeletonLoaders/ScoreComparisonLoader'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'VolumeBySource',
  props: ['widgetId'],
  data: () => ({
    loading: true,
    sources: [],
  }),
  components: {
    ScoreComparisonLoader,
  },
  methods: {
    ...DashboardModule.mapActions(['fetchVolumeBySource']),
    captalize(value) {
      return typeof value === 'string'
        ? value
            .split(/\s/)
            .map((v) => v[0].toUpperCase() + v.slice(1))
            .join(' ')
        : value
    },
  },
  async mounted() {
    await this.fetchVolumeBySource({ widgetId: this.widgetId })
    this.sources = this.selectReportValueObject(this.widgetId)
    this.loading = false
  },
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject']),
    totalSources() {
      return this.sources.reduce((a, b) => a + b.count, 0)
    },
    labels() {
      return this.sources.map((source) => source.source)
    },
    series() {
      return this.sources.map((source) => source.count)
    },
    chartOptions() {
      return {
        chart: {
          width: '100%',
          height: 380,
          type: 'donut',
        },
        labels: this.labels,
        dataLabels: { enabled: false },
        colors: ['#0D2257', '#163371', '#1E438B', '#3063C0', '#4184F4', '#78A6F4', '#B0C8F3'],
        noData: 'No data yet!',
        tooltip: {
          enabled: true,
          shared: true,
          y: {
            formatter: (value) => value,
            title: { formatter: this.captalize },
          },
        },
        plotOptions: {
          donut: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: 'left',
          verticalAlign: 'center',
          containerMargin: {
            left: 35,
            right: 60,
          },
          markers: { radius: 0 },
          itemMargin: { vertical: 4 },
          formatter: (value, opts) => {
            const count = opts.w.globals.series[opts.seriesIndex]
            const percent = Math.round((count / this.totalSources) * 100) + '%'
            return `${value} - ${percent}`
          },
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                donut: {
                  horizontal: false,
                },
              },
              chart: {
                height: 300,
              },
              legend: {
                position: 'bottom',
                itemMargin: {
                  horizontal: 10,
                },
              },
            },
          },
          {
            breakpoint: 1280,
            options: {
              plotOptions: {
                donut: {
                  horizontal: false,
                },
              },
              chart: {
                height: 300,
              },
              legend: {
                position: 'bottom',
                itemMargin: {
                  horizontal: 10,
                },
              },
            },
          },
        ],
      }
    },
  },
}
</script>
