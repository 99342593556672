<template>
  <div class="card m-2 px-4 pt-4" :class="cardSize()" v-if="widget">
    <WidgetTitle style="margin-top: -10px" :title="widget.title" :tooltip="widget.tooltip" />
    <!-- TODO add 'shown date' subtitle when allowing locking -->
    <!-- <WidgetSubtitle /> -->
    <WidgetBody class="mb-3" :widgetId="widget._id" :widgetType="widget.type" />
  </div>
</template>

<script>
import WidgetTitle from './WidgetTitle'
import WidgetSubtitle from './WidgetSubtitle'
import WidgetBody from './WidgetBody'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'DashboardWidget',
  props: ['widgetId'],
  components: {
    WidgetTitle,
    WidgetSubtitle,
    WidgetBody,
  },
  data: () => ({
    widget: null,
  }),
  computed: {
    ...DashboardModule.mapGetters(['selectWidget']),
  },
  mounted() {
    this.widget = this.selectWidget(this.widgetId)
  },
  methods: {
    cardSize() {
      switch (this.widget.size) {
        case 'x-small':
          return { 'widget-xs': true }
        case 'small':
          return { 'widget-sm': true }
        case 'medium':
          return { 'widget-md': true }
        case 'large':
          return { 'widget-lg': true }
        case 'x-large':
          return { 'widget-xl': true }
        default:
          return {}
      }
    },
  },
}
</script>

<style scoped lang="scss">
.widget-xs {
  // height: 125px;
}
.widget-sm {
  // width: 452px;
  height: 370px;
}
.widget-md {
  // width: 684px;
  height: 372px;
}
.widget-lg {
  // width: 916px;
  height: 372px;
}
.widget-xl {
  // width: 1148px;
  height: 372px;
}
</style>
