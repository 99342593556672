<template>
  <div class="mt-2">
    <b-skeleton width="60px" height="42px" />
    <b-skeleton width="120px" height="17px" class="ml-1 mt-3" />
  </div>
</template>

<script>
export default {
  name: 'ValueComparisonLoader',
}
</script>
