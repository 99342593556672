<template>
  <div v-if="!loading" class="p-4">
    <div v-if="!sources.length">- No Data</div>
    <div style="height: 250px" class="" v-else>
      <div v-for="(rating, channel, index) of channels" :key="index" class="my-3">
        <div class="row p-0 align-items-center">
          <!-- emoji -->
          <div class="col-4 d-flex text-capitalize text-left">
            {{ channel }}
          </div>

          <!-- count -->
          <div class="col-2 d-flex p-0 justify-content-center">
            <div style="font-size: 20px; margin-right: -15px" class="font-weight-bold mt-1">
              {{ rating }}
            </div>
          </div>

          <!-- comparison -->
          <div class="col-2 d-flex p-0 justify-content-center">
            <Comparison :comparison="comparisons[channel]" />
          </div>

          <!-- percentage bar -->
          <div class="col-4">
            <div class="progress bg-light" style="min-height: 22px">
              <div
                class="progress-bar"
                role="progressbar"
                style="background-color: #072f74"
                :style="`width: ${Math.round((rating / 5) * 100)}%`"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center" v-if="numPages > 1">
      <nav>
        <ul class="pagination pagination-sm">
          <li
            class="page-item"
            :class="{ active: index === page }"
            v-for="index of numPages"
            :key="index"
            @click="page = index"
          >
            <span class="page-link">{{ index }}</span>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <div v-else>
    <ScoreComparisonLoader />
  </div>
</template>

<script>
import Comparison from '../../ReportTypes/Comparison'
import ScoreComparisonLoader from '../../SkeletonLoaders/ScoreComparisonLoader'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'SatisfactionBySource',
  props: ['widgetId'],
  data: () => ({
    loading: true,
    comparisons: {},
    reportValues: {},
    sources: [],
    numPages: 0,
    page: 1,
  }),
  components: {
    Comparison,
    ScoreComparisonLoader,
  },
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject', 'selectObjectComparisonsAsNumber']),
    channels() {
      const start = (this.page - 1) * 5
      const sourcesToShow = this.sources.slice(start, start + 5)
      let channels = {}
      sourcesToShow.forEach((source) => {
        channels[source] = this.reportValues[source]
      })
      return channels
    },
  },
  methods: {
    ...DashboardModule.mapActions(['fetchAverageRatingBySource']),
  },
  async mounted() {
    await this.fetchAverageRatingBySource({ widgetId: this.widgetId })
    this.reportValues = this.selectReportValueObject(this.widgetId)
    this.sources = Object.keys(this.reportValues)
    this.numPages = Math.ceil(this.sources.length / 5)
    this.comparisons = this.selectObjectComparisonsAsNumber(this.widgetId)
    this.loading = false
  },
}
</script>

<style scoped>
.page-item.active .page-link {
  color: #12263f;
  border-color: gray;
  background: white;
}
</style>
