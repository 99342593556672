<template>
  <div :style="`min-width: ${width}px`">
    <multiselect
      close-on-select
      v-model="selected"
      :showLabels="false"
      :options="options"
      :allow-empty="allowEmpty"
      @input="updatedSelection"
      label="name"
      track-by="_id"
    >
      <span slot="noResult">No results.</span>
      <template slot="placeholder">
        <span class="text-secondary">
          {{ placeholder }}
        </span>
      </template>
      <template slot="option" slot-scope="props">
        <div>
          <span>{{ props.option.name }}</span>
        </div>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'SingleSelectFilter_Object',
  props: {
    placeholder: { type: String, default: 'Select option' },
    options: { type: Array, required: true },
    width: { type: [String, Number], default: '215' },
    initial: { type: String }, // the _id of the intital selection
    allowEmpty: { type: Boolean, default: false },
  },
  components: {
    Multiselect,
  },
  data: () => ({
    selected: null,
  }),
  methods: {
    updatedSelection(event) {
      this.$emit('update', this.selected)
    },
  },
  mounted() {
    if (this.initial) {
      const initialObject = this.options.find((o) => o._id.toString() === this.initial.toString())
      if (initialObject) {
        this.selected = initialObject
      }
    }
  },
}
</script>
