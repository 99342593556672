<template>
  <div>
    <div v-if="value !== null">
      <div class="main-text text-center">{{ value }}</div>

      <!-- comparison -->
      <div style="min-height: 28.5px">
        <div
          class="mt-2 text-center"
          :style="{ color: comparisonColor }"
          v-if="comparison !== null && isValueGreaterThanZero && !shouldHideComparison"
        >
          <span v-if="comparisonColor === 'black'" class="text-muted" style="font-size: 14px">{{
            comparisonText
          }}</span>
          <span v-else>
            <!-- up caret -->
            <span v-if="positiveComparison">
              <i class="fas fa-caret-up" v-if="!reverseCarets" />
              <i class="fas fa-caret-down" v-else />
            </span>

            <!-- down caret -->
            <span v-else>
              <i class="fas fa-caret-down" v-if="!reverseCarets" />
              <i class="fas fa-caret-up" v-else />
            </span>

            <span class="ml-1">
              {{ comparisonWithoutMinus }}
              <span class="text-muted">{{ comparisonText }}</span>
            </span>
          </span>
        </div>
        <div class="mt-2" style="height: 22.5px" v-else></div>
      </div>
    </div>

    <div
      class="d-flex align-items-center justify-content-center"
      style="min-height: 67.5px; font-weight: bold"
      v-else
    >
      No data during period
    </div>
  </div>
</template>

<script>
export default {
  name: 'ValueComparison',
  props: {
    value: {
      type: String,
    },
    comparison: {
      type: String,
    },
    reverseCarets: {
      type: Boolean,
      default: false,
    },
    comparisonVersusText: {
      type: String,
      default: 'vs last period',
    },
    hideNegativeComparison: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    comparisonText() {
      if (!this.comparison) {
        return 'No data last period'
      } else if (parseFloat(this.comparison) === 0) {
        return 'Same as last period'
      } else {
        return this.comparisonVersusText
      }
    },
    comparisonColor() {
      if (this.comparison) {
        if (parseFloat(this.comparison) < 0) {
          return 'red'
        } else if (parseFloat(this.comparison) === 0) {
          return 'black'
        } else {
          return 'green'
        }
      } else {
        return 'black'
      }
    },
    shouldHideComparison() {
      return this.hideNegativeComparison && parseFloat(this.comparison) < 0
    },
    positiveComparison() {
      return this.comparison && parseFloat(this.comparison) >= 0
    },
    comparisonWithoutMinus() {
      return parseFloat(this.comparison) < 0 ? this.comparison.substring(1) : this.comparison
    },
    isValueGreaterThanZero() {
      return parseFloat(this.value) > 0 ? true : false
    },
  },
}
</script>

<style scoped>
.main-text {
  font-size: 26px;
  font-weight: bold;
}
</style>
