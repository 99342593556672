<template>
  <b-modal id="daypart-modal" v-model="modalOpened" hide-footer hide-header body-class="p-0">
    <div class="modal-card card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-header-title">Edit Daypart Ranges</h4>
          </div>
          <div class="col-auto">
            <button @click="modalOpened = false" class="close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" style="max-height: 650px">
        <div class="row">
          <div
            v-for="(range, daypart) in weekDayparts"
            :key="daypart"
            class="col-lg-9 col-sm-1 mb-4"
          >
            <label class="text-capitalize text-secondary">{{ daypart }}</label>
            <div class="d-flex align-items-center">
              <flat-pickr
                v-model="range.start"
                class="form-control mr-4"
                :config="timePickerConfig"
                placeholder="start"
                :aria-label="daypart + '-start'"
                :name="daypart + '-start'"
              />
              <span class="mx-2">-</span>
              <flat-pickr
                v-model="range.end"
                class="form-control"
                :config="{
                  ...timePickerConfig,
                }"
                placeholder="end"
                :aria-label="daypart + '-end'"
                :name="daypart + '-end'"
              />
            </div>
          </div>
        </div>
        <div class="row pt-4">
          <div class="col-12">
            <button class="btn btn-primary col-12" :disabled="!isFormValid" @click="updateDayparts">
              Set
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { cloneDeep } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')

export default {
  name: 'SurveyDaypartsModal',
  data() {
    return {
      timePickerConfig: {
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: 'h:i K',
        altFormat: 'h:i K',
        altInput: true,
      },
      modalOpened: false,
      weekDayparts: {},
    }
  },
  mounted() {
    this.weekDayparts = cloneDeep(this.selectDayparts)
  },
  computed: {
    ...UserModule.mapGetters(['selectDayparts']),
    isFormValid() {
      return true
    },
  },
  methods: {
    ...UserModule.mapActions(['setDayparts']),
    async updateDayparts() {
      await this.setDayparts(cloneDeep(this.weekDayparts))
      this.modalOpened = false
      setTimeout(() => {
        this.$emit('refresh')
      }, 200)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .flatpickr-calendar {
  width: 160px;
}
</style>
