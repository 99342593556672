<template>
  <div>
    <ValueComparison
      style="height: 69px"
      :value="selectReportValue(widgetId)"
      :comparison="comparison"
      :comparisonVersusText="'with Ovation'"
      v-if="!loading"
    />
    <ValueComparisonLoader v-else />
  </div>
</template>

<script>
import ValueComparison from '@/components/Modules/Dashboard/Widget/ReportTypes/ValueComparison'
import ValueComparisonLoader from '@/components/Modules/Dashboard/Widget/SkeletonLoaders/ValueComparisonLoader'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'ReviewsPerMonth',
  props: ['widgetId'],
  components: {
    ValueComparison,
    ValueComparisonLoader,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...DashboardModule.mapGetters([
      'selectComparisonPercent',
      'selectReportValue',
      'selectComparisonNumber',
    ]),
    comparison() {
      if (this.selectComparisonNumber(this.widgetId) <= 0) {
        return null
      }
      return this.selectComparisonPercent(this.widgetId)
    },
  },
  methods: {
    ...DashboardModule.mapActions(['fetchReviewsPerMonth']),
  },
  async mounted() {
    await this.fetchReviewsPerMonth({ widgetId: this.widgetId })
    this.loading = false
  },
}
</script>
