<template>
  <div class="px-3">
    <div v-for="index of 5" :key="index" class="my-3">
      <div class="row p-0 align-items-center">
        <div class="col-2 d-flex justify-content-center">
          <b-skeleton width="60px" height="36px" />
        </div>
        <div class="col-2 d-flex justify-content-center">
          <b-skeleton width="60px" height="36px" />
        </div>
        <div class="col-2 d-flex justify-content-center">
          <b-skeleton width="60px" height="36px" />
        </div>
        <div class="col-6 d-flex justify-content-center">
          <b-skeleton width="160px" height="36px" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoreComparisonLoader',
}
</script>
