<template>
  <div class="mb-1 text-center text-muted">
    <span v-b-tooltip.hover.top :title="tooltip" class="cursor-pointer" style="font-size: 14px">
      {{ title }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'WidgetTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
  },
}
</script>
