<template>
  <div>
    <div :style="{ color: comparisonColor }" v-if="comparison !== null">
      <span v-if="comparisonColor === 'black'" class="text-muted">{{ comparisonText }}</span>
      <span v-else>
        <!-- up caret -->
        <span v-if="positiveComparison">
          <i class="fas fa-caret-up" v-if="!reverseCarets" />
          <i class="fas fa-caret-down" v-else />
        </span>

        <!-- down caret -->
        <span v-else>
          <i class="fas fa-caret-down" v-if="!reverseCarets" />
          <i class="fas fa-caret-up" v-else />
        </span>

        <span class="ml-2">
          <span style="font-size: 12px">{{ comparisonWithoutMinus }}</span>
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Comparison',
  props: {
    comparison: {
      type: String,
    },
    reverseCarets: {
      type: Boolean,
      default: false,
    },
    reverseColors: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    comparisonText() {
      if (!this.comparison) {
        return ''
      } else if (parseFloat(this.comparison) === 0) {
        return '-0.0'
      }
      return ''
    },
    comparisonColor() {
      if (this.comparison) {
        if (parseFloat(this.comparison) < 0) {
          return !this.reverseColors ? 'red' : 'green'
        } else if (parseFloat(this.comparison) === 0) {
          return '#95aac9' // text-muted color
        } else {
          return !this.reverseColors ? 'green' : 'red'
        }
      } else {
        return 'black'
      }
    },
    positiveComparison() {
      return this.comparison && parseFloat(this.comparison) >= 0
    },
    comparisonWithoutMinus() {
      return parseFloat(this.comparison) < 0 ? this.comparison.substring(1) : this.comparison
    },
  },
}
</script>
