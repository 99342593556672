<template>
  <div>
    <ValueComparison
      :value="selectReportValue(widgetId)"
      :comparison="selectComparisonPercent(widgetId)"
      v-if="!loading"
    />
    <ValueComparisonLoader v-else />
  </div>
</template>

<script>
import ValueComparison from '@/components/Modules/Dashboard/Widget/ReportTypes/ValueComparison'
import ValueComparisonLoader from '@/components/Modules/Dashboard/Widget/SkeletonLoaders/ValueComparisonLoader'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'ReviewCreatedCount',
  props: ['widgetId'],
  components: {
    ValueComparison,
    ValueComparisonLoader,
  },
  data: () => ({
    loading: true,
  }),
  computed: {
    ...DashboardModule.mapGetters(['selectComparisonPercent', 'selectReportValue']),
  },
  methods: {
    ...DashboardModule.mapActions(['fetchReviewCreatedCount']),
  },
  async mounted() {
    await this.fetchReviewCreatedCount({ widgetId: this.widgetId })
    this.loading = false
  },
}
</script>
