<template>
  <div class="">
    <div class="row">
      <div class="col-12">
        <template v-if="!loading">
          <hr v-if="Allplatforms.length" />
          <div v-for="p in Allplatforms" :key="p.source">
            <div class="row align-items-center">
              <div class="col-auto">
                <a class="avatar avatar-sm" href="#!">
                  <img
                    :alt="p.source"
                    class="avatar-img rounded"
                    :src="`/theme/img/platforms/${p.source}.svg`"
                  />
                </a>
              </div>
              <!--- end of platform logo -->
              <div class="col-auto pr-0">
                <StarRating class="mb-1" :rating="p.ratingAfterJoining" />
                <p class="card-text small text-muted">{{ p.reviewCount }} reviews</p>
              </div>
              <div class="col">
                <div class="d-flex flex-column">
                  <div>
                    <animated-number
                      class="h2 m-0"
                      :duration="500"
                      :value="p.ratingAfterJoining"
                      :formatValue="formatValue"
                    />
                    <span
                      v-if="comparison(p.ratingAfterJoining, p.ratingBeforeJoining) > 0"
                      style="font-size: 15px; color: green"
                      class="mt--2 ml-3"
                    >
                      <i class="fas fa-caret-up fa-md mr-1" />
                      <animated-number
                        :duration="500"
                        :value="comparison(p.ratingAfterJoining, p.ratingBeforeJoining)"
                        :formatValue="formatValue"
                      />
                    </span>
                  </div>
                  <span
                    v-if="comparison(p.ratingAfterJoining, p.ratingBeforeJoining) > 0"
                    class="small text-muted"
                  >
                    {{ formatValue(p.ratingBeforeJoining) }} before Ovation
                  </span>
                </div>
              </div>
            </div>
            <hr v-if="platforms.length > 1" />
          </div>
        </template>

        <div
          v-if="numPages > 1 && !loading"
          :style="Allplatforms.length < 3 ? { marginTop: '95x' } : ''"
          class="d-flex justify-content-center child"
        >
          <nav>
            <ul class="pagination pagination-sm">
              <li
                class="page-item"
                :class="{ active: index === page }"
                v-for="index of numPages"
                :key="index"
                @click="page = index"
              >
                <span class="page-link">{{ index }}</span>
              </li>
            </ul>
          </nav>
        </div>

        <div v-if="loading">
          <ValueComparisonLoader />
          <ValueComparisonLoader />
          <ValueComparisonLoader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import StarRating from '@/components/Modules/Review/StarRating'
import AnimatedNumber from 'animated-number-vue'
import { formatAvg } from '@/lib/chart/format'
import ValueComparisonLoader from '@/components/Modules/Dashboard/Widget/SkeletonLoaders/ValueComparisonLoader'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'ReviewCurrentReviewsAndRatings',
  props: ['widgetId'],
  components: {
    StarRating,
    AnimatedNumber,
    ValueComparisonLoader,
  },
  data: () => ({
    loading: true,
    numPages: 0,
    platforms: [],
    page: 1,
  }),
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject']),

    Allplatforms() {
      const start = (this.page - 1) * 3
      const platformsToShow = this.platforms.slice(start, start + 3)
      return platformsToShow
    },
  },
  methods: {
    ...DashboardModule.mapActions(['fetchCurrentReviewsAndRatings']),

    formatValue(rating) {
      return formatAvg(rating)
    },
    comparison(value, comp) {
      return parseFloat(value - comp).toFixed(1)
    },
  },
  async mounted() {
    await this.fetchCurrentReviewsAndRatings({ widgetId: this.widgetId })
    this.platforms = this.selectReportValueObject(this.widgetId)
    this.numPages = Math.ceil(this.platforms.length / 3)
    this.loading = false
  },
}
</script>
<style scoped>
.page-item.active .page-link {
  color: #12263f;
  border-color: gray;
  background: white;
}
</style>
