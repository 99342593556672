<template>
  <div>
    <SurveyDaypartsModal @refresh="refresh" />
    <div style="margin-top: 45px" v-if="!loading">
      <div v-if="noSurveys">- No data during selected period</div>
      <div style="margin-top: -30px" v-else>
        <div class="dropdown">
          <a
            href="javascript:;"
            role="button"
            aria-expanded="false"
            aria-haspopup="true"
            class="dropdown-ellipses dropdown-toggle float-right mt--4"
            data-toggle="dropdown"
          >
            <i class="fe fe-more-horizontal"></i>
          </a>
          <ul class="dropdown-menu dropdown-menu-right">
            <li>
              <a class="dropdown-item" href="javascript:;" v-b-modal="'daypart-modal'">
                Edit Dayparts
              </a>
            </li>
          </ul>
        </div>

        <apexchart type="bar" height="300px" :series="series" :options="chartOptions" />
      </div>
    </div>

    <div v-else><ScoreComparisonLoader /></div>
  </div>
</template>

<script>
import ScoreComparisonLoader from '../../SkeletonLoaders/ScoreComparisonLoader'
import SurveyDaypartsModal from '@/components/Modals/SurveyDaypartsModal'
import { createNamespacedHelpers } from 'vuex'

const DashboardModule = createNamespacedHelpers('dashboard')

export default {
  name: 'SatisfactionDayparts',
  props: ['widgetId'],
  data: () => ({
    loading: true,
    series: [
      {
        name: 'morning',
        data: [],
      },
      {
        name: 'afternoon',
        data: [],
      },
      {
        name: 'evening',
        data: [],
      },
    ],
  }),
  components: {
    ScoreComparisonLoader,
    SurveyDaypartsModal,
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    ...DashboardModule.mapActions(['fetchSatisfactionDayparts']),
    async refresh() {
      this.loading = true
      await this.fetchSatisfactionDayparts({ widgetId: this.widgetId })
      this.series = this.series.map((dp) => ({ name: dp.name, data: [] }))
      await this.buildSeries()
      this.loading = false
    },
    async buildSeries() {
      let dayparts = { morning: 0, afternoon: 1, evening: 2 }
      this.selectReportValueObject(this.widgetId).forEach((weekday) => {
        Object.keys(dayparts).forEach((dp) => {
          this.series[dayparts[dp]]['data'].push(weekday[dp]?.rating || 0)
        })
      })
    },
  },
  computed: {
    ...DashboardModule.mapGetters(['selectReportValueObject']),
    noSurveys() {
      let noSurveys = true
      this.series.forEach((dp) => {
        if (dp.data?.length && dp.data.some((data) => data !== 0)) noSurveys = false
      })
      return noSurveys
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: { show: false },
          zoom: {
            enabled: false,
          },
        },
        colors: ['#FFBE67', '#4184F4', '#072F74'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '45%',
            borderRadius: 5,
          },
        },
        dataLabels: {
          enabled: false,
        },
        grid: {
          borderColor: '#D2DDEC',
          strokeDashArray: 2,
        },
        noData: {
          text: 'No data yet!',
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          type: 'category',
          categories: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday',
          ],
          tooltip: { enabled: false },
          labels: {
            style: { colors: '#95aac9' },
          },
          tickPlacement: 'on',
          axisBorder: { show: false },
        },
        yaxis: [
          {
            seriesName: 'Morning',
            labels: {
              style: { colors: '#95aac9' },
              formatter: (value) => parseFloat(value).toFixed(1),
            },
            forceNiceScale: true,
            min: 1,
            max: 5,
          },
          {
            show: false,
            seriesName: 'Afternoon',
            min: 1,
            max: 5,
          },
          {
            show: false,
            seriesName: 'Evening',
            min: 1,
            max: 5,
          },
        ],
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          shared: false,
          x: {
            formatter: (value, opts) => this.$moment(value - 1, 'd').format('dddd'),
          },
        },
        legend: {
          show: true,
          position: 'top',
          horizontalAlign: 'right',
          markers: { radius: 0 },
          itemMargin: {
            horizontal: 5,
          },
        },
      }
    },
  },
}
</script>
